.hero {
  h2,
  h3 {
    margin-top: 0;
  }

  &__masthead {
    height: calc(50vh - 50px);
    position: relative;
    text-align: center;

    @media (min-width: @screen-lg-min) {
      height: calc(80vh - 50px);
    }

    .hero--tracker &{
      background-image: url('../img/heros/Tracker.gif'), url('../img/heros/bk/hero-background--2.jpg');
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: center;
      background-position-y: 50px;
      background-size: contain;
    }
    .hero--engineer &{
      background-image: url('../img/heros/Engineer.gif'), url('../img/heros/bk/hero-background--2.jpg');
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: center;
      background-position-y: 50px;
      background-size: contain;
    }
    .hero--marksman &{
      background-image: url('../img/heros/Marksman.gif'), url('../img/heros/bk/hero-background--2.jpg');
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: center;
      background-position-y: 50px;
      background-size: contain;
    }
    .hero--protege & {
      background-image: url('../img/heros/Protege.gif'), url('../img/heros/bk/hero-background--2.jpg');
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: center;
      background-position-y: -5%;
      background-size: 55%;
    }
    .hero--creator & {
      background-image: url('../img/heros/Creator.gif'), url('../img/heros/bk/hero-background--2.jpg');
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: center;
      background-position-y: 50px;
      background-size: contain;
    }
    .hero-logo {
      //bottom: (@line-height-computed/2);
      bottom: 10%;
      left: -30%;
      margin: 0 auto;
      position: absolute;
      right: 25%;
      width: 40%;
    }

    img {
      float: right;
    }
  }

  &__intro {
    .angled-edges();
    background-color: @brand-primary;
    color: @off-white;
    padding: (@padding-large-vertical * 3) 0;

    .hero--tracker & {
      background-color: @brand-tracker;
    }

    .hero--engineer & {
      background-color: @brand-engineer;
    }

    .hero--marksman & {
      background-color: @brand-marksman;
    }

    .hero--creator & {
      background-color: @brand-creator;
    }

    .hero--protege & {
      background-color: @brand-protege;
      p {
        color: @brand-primary;
      }
    }

    p {
      margin-bottom: 0;
    }

    h1 {
      margin-top: 0;
    }
  }


  &__challenges {
    padding: (@padding-large-vertical * 6) 0;
    text-align: center;

    .challenges__nav {
      .clearfix();
      margin-bottom: @line-height-computed;
      text-align:center;

      a {
        display: block;
        float: left;
        padding: (@padding-base-vertical * 2) @padding-base-horizontal;
        text-transform: uppercase;
        width: 100%;

        &:hover,
        &:focus {
          background: @off-white;
          color: @brand-primary;
          text-decoration: none;
        }

        &.active {
          background: @brand-primary;
          box-shadow: 0 10px 15px -10px fade(@brand-primary, 100%);
          color: @off-white;
        }

        @media (min-width: @screen-md-min) {
          width: 23%;
          margin-left: 1%;
          margin-right: 1%;
          line-height: 1.25;

          span {
            display: block;
          }
        }
      }
    }

    .challenge {
      background: @brand-primary;
      background-position: bottom right;
      background-repeat: no-repeat;
      box-shadow: 0 15px 30px -15px fade(@brand-primary, 100%);
      color: @off-white;
      display: none;
      padding: (@padding-large-vertical * 2) (@padding-large-horizontal * 2);
      position: relative;
      text-align: left;

      @media (min-width: @screen-lg-min) {
        padding: (@padding-large-vertical * 2) (@padding-large-horizontal * 12) (@padding-large-vertical * 2) (@padding-large-horizontal * 2);
      }

      h3 {
        font-size: @font-size-h1;
      }

      .btn {
        @media (min-width: @screen-lg-min) {
          position: absolute;
          bottom: (@padding-large-vertical * 2);
          right: (@padding-large-horizontal * 2);
        }
      }

      &.active {
        display: block;
      }
        //marksman
      &#challenge--new {
        background-image: url('../img/Challenges/challenge--new.png');
      }
      &#challenge--nemesis {
        background-image: url('../img/Challenges/challenge--nemesis.png');
      }
      &#challenge--go-big {
        background-image: url('../img/Challenges/challenge--go-big.png');
      }
      &#challenge--no-superheroes {
        background-image: url('../img/Challenges/challenge--no-superheroes.png');
      }
      &#challenge--no-superheroes {
        background-image: url('../img/Challenges/challenge--no-superheroes.png');
      }
      &#challenge--performing {
        background-image: url('../img/Challenges/challenge-tracker--performance.png');
      }
      &#challenge--busiest {
        background-image: url('../img/Challenges/challenge-tracker--times.png');
      }
      &#challenge--effective {
        background-image: url('../img/Challenges/challenge-tracker--content.png');
      }
      &#challenge--responsive {
        background-image: url('../img/Challenges/challenge-tracker--analytics.png');
      }
        //protege
      &#challenge--newBusiness {
        background-image: url('../img/Challenges/challenge-protege--new.png');
      }
      &#challenge--increase {
        background-image: url('../img/Challenges/challenge-protege--engagement.png');
      }
      &#challenge--frustrated {
        background-image: url('../img/Challenges/challenge-protege--frustrated.png');
      }
      &#challenge--complex {
        background-image: url('../img/Challenges/challenge-protege--complex.png');
      }
        //engineer
      &#challenge--new {
        background-image: url('../img/Challenges/challenge-engineer--new.png');
      }
      &#challenge--idea {
        background-image: url('../img/Challenges/challenge-engineer--listen.png');
      }
      &#challenge--better {
        background-image: url('../img/Challenges/challenge-engineer--better.png');
      }
      &#challenge--refresh {
        background-image: url('../img/Challenges/challenge-engineer--reinvigorate.png');
      }
      //creator
      &#challenge--first {
        background-image: url('../img/Challenges/challenge-creator--new.png');
      }
      &#challenge--grown {
        background-image: url('../img/Challenges/challenge-creator--outgrown.png');
      }
      &#challenge--boring {
        background-image: url('../img/Challenges/challenge-creator--boring.png');
      }
      &#challenge--inspire {
        background-image: url('../img/Challenges/challenge-creator--inspireme.png');
      }
    }
  }

  &__background::before{

}
  &__skills {
    .angled-edges();
    background-color: @brand-primary;
    color: @text-color;
    padding: (@padding-large-vertical * 3) 0;
    text-align: center;

    .hero--tracker & {
      background-color: @brand-tracker;
      color: @off-white;
    }

    .hero--engineer & {
      background-color: @brand-engineer;
      color: @off-white;
    }

    .hero--marksman & {
      background-color: @brand-marksman;
    }

    .hero--creator & {
      background-color: @brand-creator;
    }

    .hero--protege & {
      background-color: @brand-protege;
    }

    h2 {
      border-bottom: 1px solid fade(@gray-base, 50%);
      padding-bottom: @padding-large-vertical;
      margin-bottom: 0;
    }

    .skills-slide {
      height: (@line-height-computed * 18);
      overflow: scroll;
      position: relative;
      text-align: left;

      @media (min-width: @screen-md-min) {
        height: (@line-height-computed * 10);
      }
      &__container {
        @media (min-width: @screen-md-min) {

        }
        position: absolute;
        top: 0;
        transition: all 1000ms cubic-bezier(0.645, 0.045, 0.355, 1);
      }

      &__image {
        background: transparent url('../img/heros/comics/comic-engineer.png') top center repeat-y;
        background-size: contain;
        position: absolute;
        bottom: -15px;
        left: 0;
        top: -15px;
        width: 40%;

        .hero--tracker & {
          background-image: url('../img/heros/comics/comic-tracker.png');
        }

        .hero--engineer & {
          background-image: url('../img/heros/comics/comic-engineer.png');
        }

        .hero--marksman & {
          background-image: url('../img/heros/comics/comic-marksman.png');
        }

        .hero--creator & {
          background-image: url('../img/heros/comics/comic-creator.png');
        }

        .hero--protege & {
          background-image: url('../img/heros/comics/comic-protege.png');
        }
      }

      &__slide {
        display: block;
        margin: 0;
        margin-left: 30%;
        padding: (@line-height-computed * 2) @grid-gutter-width;

        @media (min-width: @screen-md-min) {
          margin-left: 40%;
        }
      }
    }

    .skills-list {
      border-top: 1px solid fade(@gray-base, 50%);
      padding-top: @padding-large-vertical;
      text-align: center;

      li {
        margin: 0;
        padding: 0;

        a {
          &.active {
            font-family: @font-family-bold;
            font-weight: 800;
            text-decoration: none;
          }

          &:hover {
            color: @off-white;
            text-decoration: none;
          }

          .hero--tracker &,
          .hero--engineer & {
            color: fade(@off-white, 75%);

            &:hover,
            &.active {
              color: @white;
            }
          }
        }

        &:after {
          color: fade(@gray-base, 50%);
          content: '\f111';
          font-family: @icon-font-name;
          margin: 0 @padding-base-horizontal;
        }

        &:last-child {
          &:after {
            content: '';
          }
        }
      }
    }

  }

  &__services {
    background-color: @brand-primary;

    text-align: center;
    margin-top: 10%;
    margin-bottom : 10%;

    padding-top: 5%;
    padding-bottom : 5%;

    min-height: 60vh !important;

    background-size: contain;
    background-repeat: no-repeat;

    border-top: 1em solid @white;
    border-bottom: 1em solid @white;

    color: @off-white;

    .hero--tracker & {
      transform: skewY(1.2deg);
      background-color: @brand-tracker;
      background-image: url('../img/services/tracker/tracker.png');
      background-position: bottom left;
    }

    .hero--engineer & {
      transform: skewY(1.2deg);
      background-image: url('../img/services/engineer/engineer.png');
      background-color: @brand-engineer;
      background-position: bottom left;
    }

    .hero--marksman & {
      transform: skewY(1.2deg);
      background-color: @brand-marksman;
      background-image: url('../img/services/marksman/marksman.png');
      background-position: bottom left;
    }

    .hero--creator & {
      transform: skewY(-1.2deg);
      background-color: @brand-creator;
      background-image: url('../img/services/creator/creator.png');
      background-position: bottom right;
    }

    .hero--protege & {
      transform: skewY(-1.2deg);
      background-color: @brand-protege;
      background-image: url('../img/services/protege/protege.png');
      background-position: bottom right;
    }

    h2 {
      border-bottom: 1px solid fade(@gray-base, 50%);
      padding-bottom: @padding-large-vertical;
      margin-bottom: 0;
    }


      &__container {
        @media (min-width: @screen-md-min) {
          // background: transparent url('../img/skills-long.png') top left no-repeat;
          // background-size: contain;
        }
        position: absolute;
        top: 0;
        transition: all 1000ms cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }
  }

  body.services{
    background-image: url('../img/services/parallax.jpg');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .services-title > a{
    color: #fff !important;
    font-size: 40pt;
  }

  .spec-title{
    color: #fff;
    text-align: center;
  }

  .services_text{
    text-align: left !important;
  }

  .leftSkew{transform: skewY(-1.2deg);}

  .rightSkew{transform: skewY(1.2deg);}

  .services-specs{
    text-align: center !important;
  }
  .services-spec{
    padding-top: 2%;
    padding-bottom: 2%;
    height: 20vh;
  }
  .services-img{
    display: block;
    margin: auto;
    max-width: 90pt;
    max-height: 90pt;
  }
  .services-btn{
    z-index: 10;
    position: absolute;
    bottom: 10%;
    padding-top: 10%;
  }
  .btn-lg{
    padding: 15px 30px;
    font-size: 20pt;
  }

  .services-btn.e-btn{left: 21%;}
  .services-btn.c-btn{left: 74%;}
  .services-btn.m-btn{left: 14%;}
  .services-btn.p-btn{left: 80%;}
  .services-btn.t-btn{left: 14%;}

  .dp__services{
    border: 1em solid @white;
    background: #242424;
    margin-left: 5%;
    margin-right: 5%;
    color: #fff;
    margin-bottom: 10%;
  }

  .dp__services > .service-text{
    padding-left: 8%;
    padding-right: 8%;
    padding-bottom: 8%;
  }
  .dp__services > h2, .dp__services > .btn-container{
    padding-bottom: 2%;
    text-align: center;
  }
  .dp__services > .btn-container{
    // position: absolute;
    // left: 50%;
    // transform: translateX(-50%);
    // bottom: 10%;
  }

  .dp__services > .services-img{
    width: 90pt;
    height: 90pt;
    margin-top: 10%;
  }

  .hero--services{
    padding-bottom: 10%;
  }

  .row.heroes, .footer{
    background: #f2f2f2 url(../img/seamless_paper_texture.png);
  }

  .hero--heads{
    position: fixed;
    bottom: 0%;
    z-index: 200;
    width: 100%;
  }

.hero--heads > .row > .head > img{
  display:block;
  margin:auto;
  height: 18vh;
}

.heroes {
  padding: (@padding-large-vertical * 6) 0;

  .dp-hero {
    a {
      .aspect-ratio(9, 16);
      background: @white;
      border: 6px solid @brand-primary;
      box-shadow: 0 15px 30px -15px fade(@brand-primary, 100%);
      color: @off-white;
      display: block;
      margin-left: (@padding-large-vertical * 2);
      margin-right: (@padding-large-vertical * 2);
      overflow: hidden;
      padding: @padding-large-vertical @padding-large-horizontal;
      position: relative;
      text-align: center;
      transition: all 0.5s ease;

      &:hover {
        cursor: pointer;
        top: -1em;

        span.btn {
          background-color: fade(@btn-default-bg, 85%);
        }
      }

      &.hero--protege {
        &:hover {
          background-color: @brand-protege;
          svg {
            .colour--protege {
              fill: @white;
            }
          }
        }
      }
      &.hero--creator {
        &:hover {
          background-color: @brand-creator;
        }
      }
      &.hero--marksman {
        &:hover {
          background-color: @brand-marksman;

          svg {
            .colour--marksman {
              fill: @white;
            }
          }
        }
      }
      &.hero--engineer {
        &:hover {
          background-color: @brand-engineer;

          svg {
            .font--engineer {
              fill: @white;
            }
          }
        }
      }

      .content {
        img {
          z-index: 1;
          margin-top: (-@padding-large-vertical*2);
        }

        .dp-hero__logo {
          width: 80%;
          height: auto;
          margin: (@padding-large-vertical*2) auto 0;
          z-index: 2;
        }

        span.btn {
          position: absolute;
          bottom: (@padding-large-vertical * 2);
          left: 50%;
          transform: translateX(-50%);
          z-index: 2;
        }

        &:after {
          #gradient > .radial(transparent; fade(@brand-primary, 50%));
          bottom: 0;
          content: '';
          display: block;
          left: 0;
          opacity: 1;
          position: absolute;
          top: 0;
          right: 0;
          z-index: 0;
        }
      }
    }
  }
}

.banter__item{
  width: 40px;
  height: auto;
}

.banter__item>img{
  padding: 0pt 0pt;
  width: auto;
  height: calc(8vh);
  display:block;
  margin:auto;
}

body{
  overflow-x: hidden;
}

.hero--text{display: none;}
.hero-text-active{display: block;}

@media screen and (max-device-width: 480px) and (orientation: portrait){
  .banter__item>img{
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;
    width: 30px;
    height: auto;
  }}
  .panel {
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 20px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
}

.hero-message{
  left: 160%;
  top: 0%;
}

.banter-system{
    margin-top: 3%;
}

.heads{
  background-image: url('../img/headshots/sidebar--bg.png');
  background-size: contain;
  background-repeat: no-repeat;
  height: calc(60vh);
  background-position: center right;
  padding-top: 1%;
  padding-bottom: 1%;
}

.hero-image{
      padding-top: 3%;
}

.heads > .active > .hero-image{
  background-image: url('../img/headshots/sidebar--tab.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center right;
  padding-top: 0%;
  left: 160%;
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 0s;
  animation: slide 0.5s forwards;
  animation-delay: 0s;
}

.heads > .inactive > .hero-image{
  left: 0%;
  -webkit-animation: slidebk 0.5s forwards;
  -webkit-animation-delay: 0s;
  animation: slidebk 0.5s forwards;
  animation-delay: 0s;
}

.heads > .banter-hero{
  display: none;
  position: absolute;
  top: 40%;
  left: -10%;
}

.heads > .banter-hero > .hero-message{left: 0%;}

@-webkit-keyframes slide {100% { left: 0%; }}
@keyframes slide {100% { left: 0%; }}
@-webkit-keyframes slidebk {100% { left: 160%; }}
@keyframes slidebk {100% { left: 160%; }}

.activeHead{
  display: none !important;
}

.head.active > .normalHead{
  display: none !important;
  height: 18vh !important;
}
.head.active > .activeHead{
  display: block !important;
  height: 20vh !important;
}

.heads > .active > .hero-message{
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 0s;
  animation: slide 0.5s forwards;
  animation-delay: 0s;
}

.heads > .active > .hero-image > .banter__item > img{
  height: calc(12vh);
  padding: 0pt 10pt;
}

.heads > .active > .hero-image > .banter__item > .normalHead{display: none !important;}
.heads > .active > .hero-image > .banter__item > .activeHead{display: block !important;}

p.speech {
  padding: 3% 3%;
	position: relative;
	border: 4px solid #333;
  border-radius: 15pt;
  text-decoration: none;
  min-width: 150pt;
  min-height: 50pt;
}
p.speech-right:after, p.speech-right:before {
	left: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

p.speech-left:after, p.speech-left:before {
	left: -25pt;
	top: 40%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

p.speech-right:after {
	border-color: rgba(255, 255, 255, 0);
  border-width: 10px;
  margin-top: -2%;
}
p.speech-right:before {
	border-color: rgba(51, 51, 51, 0);
	border-left-color: #333;
  border-width: 15px;
  margin-top: -3%;
}

p.speech-left:after {
	border-color: rgba(255, 255, 255, 0);
  border-width: 21px;
  margin-top: -2%;
}
p.speech-left:before {
	border-color: rgba(51, 51, 51, 0);
	border-right-color: #333;
  border-width: 16px;
  margin-top: -3%;
}

.speech-engineer{background: rgba(144,12,63, 0.3);}
.speech-engineer.right:before{border-color: transparent rgba(144,12,63, 0.3);}
.speech-engineer:before {border-color: rgba(144,12,63, 0.3) transparent;}
.speech-engineer.right:after{border-color: transparent rgba(144,12,63, 0.0);}
.speech-engineer.left:after{border-color: rgba(144,12,63, 0.0) transparent;}
.speech-engineer.left:before{border-color: transparent rgba(144,12,63, 0.3);}

.speech-tracker{background: rgba(88,24,69, 0.3);}
.speech-tracker.right:before{border-color: transparent rgba(88,24,69, 0.3);}
.speech-tracker:before {border-color: rgba(88,24,69, 0.3) transparent;}
.speech-tracker.right:after{border-color: transparent rgba(88,24,69, 0.0);}
.speech-tracker.left:after{border-color: rgba(88,24,69, 0.0) transparent;}
.speech-tracker.left:before{border-color: transparent rgba(88,24,69, 0.3);}

.speech-creator{background: rgba(255,87,26, 0.3);}
.speech-creator.right:before{border-color: transparent rgba(255,87,26, 0.3);}
.speech-creator:before {border-color: rgba(255,87,26, 0.3) transparent;}
.speech-creator.right:after{border-color: transparent rgba(255,87,26, 0.0);}
.speech-creator.left:after{border-color: rgba(255,87,26, 0.0) transparent;}
.speech-creator.left:before{border-color: transparent rgba(255,87,26, 0.3);}

.speech-protege{background: rgba(255,195,15, 0.3);}
.speech-protege.right:before{border-color: transparent rgba(255,195,15, 0.3);}
.speech-protege:before {border-color: rgba(255,195,15, 0.3) transparent;}
.speech-protege.right:after{border-color: transparent rgba(255,195,15, 0.0);}
.speech-protege.left:after{border-color: rgba(255,195,15, 0.0) transparent;}
.speech-protege.left:before{border-color: transparent rgba(255,195,15, 0.3);}

.speech-marksman{background: rgba(206,4,56, 0.3);}
.speech-marksman.right:before{border-color: transparent rgba(206,4,56, 0.3);}
.speech-marksman:before {border-color: rgba(206,4,56, 0.3) transparent;}
.speech-marksman.right:after{border-color: transparent rgba(206,4,56, 0.0);}
.speech-marksman.left:after{border-color: rgba(206,4,56, 0.0) transparent;}
.speech-marksman.left:before{border-color: transparent rgba(206,4,56, 0.3);}

.banter-row{
  height: 33%;
  padding-top: 5%;
}

.banter__item > img {
  margin-right: 2%;
}

.heroPage__logo{
  display:block;
  margin:auto;
  max-width: 50%;
  bottom: 0px;
}

//background section
.hero__background{
    margin-top: 5%;
    transform: skewY(-3.2deg);
    display: block;
    height: 160vh;
    z-index: 1;
}

.hero--border{
  top: -3%;
  width: 100%;
  z-index: 5;
  position: absolute;
}

#protoge-bk{background: url('../img/heros/protege/bk.jpg');}
#protoge-bk .hero--asset{background: url('../img/heros/protege/parallax.png') no-repeat fixed;}
#protoge-bk .hero-parallax{background: url('../img/heros/protege/hero.png') no-repeat fixed;}

#tracker-bk{background: url('../img/heros/tracker/bk.jpg');}
#tracker-bk .hero--asset{background: url('../img/heros/tracker/parallax.png') no-repeat fixed;}
#tracker-bk .hero-parallax{background: url('../img/heros/tracker/hero.png') no-repeat fixed;}

#marksman-bk{background: url('../img/heros/marksman/bk.jpg');}
#marksman-bk .hero--asset{background: url('../img/heros/marksman/parallax.png') no-repeat fixed;}
#marksman-bk .hero-parallax{background: url('../img/heros/marksman/hero.png') no-repeat fixed;}

#engineer-bk{background: url('../img/heros/engineer/bk.jpg');}
#engineer-bk .hero--asset{background: url('../img/heros/engineer/parallax.png') no-repeat fixed;}
#engineer-bk .hero-parallax{background: url('../img/heros/engineer/hero.png') no-repeat fixed;}

#creator-bk{background: url('../img/heros/creator/bk.jpg');}
#creator-bk .hero--asset{background: url('../img/heros/creator/parallax.png') no-repeat fixed;}
#creator-bk .hero-parallax{background: url('../img/heros/creator/hero.png') no-repeat fixed;}

.hero__background{
  background-repeat: no-repeat;
}

.hero--asset{
  background-size: 65% !important;
  height: 160vh;
  margin: 0 auto;
  padding: 0;
  position: absolute;
  z-index: -1;
  width: 100%;
  overflow: hidden;
}

.hero-parallax{
  background-size: 85% !important;
  height: 160vh;
  margin: 0 auto;
  padding: 0;
  position: absolute;
  z-index: -1;
  width: 100%;
  overflow: hidden;
}

.power{
  padding-left: 10%;
  padding-top: 5%;
}

.power img{
  max-width: 35vw;
}

.hero__benefits{
  background: url('../img/seamless_paper_texture.png');
  border-top: 15pt solid #fff;
  min-height: 60vh;
  margin-top: -6%;
  z-index: 2;
  position: relative;
  margin-bottom: 3%;
}

.background-text{
  padding-left: 10%;
  padding-top: 5%;
}

.background-text p{
  font-weight: bold;
  font-size: 16pt;
}

.hero--arrow.img-responsive{
  max-width: 70%;
}

#arrow{
  position: absolute;
  display: none;
  z-index: 5;
  left: 13%;
  top: -25%;
}

.hero--arrow--text{
  font-size: 30pt;
  transform: skewY(-3.2deg);
  margin-left: -45%;
}

.background__logo{
  margin-top: 10vh;
  padding-left: 10%;
  width: 40vw;
}

.background__straight{
  transform: skewY(3.2deg);
}

#benefits-list{
  list-style-image: url('../img/heros/point.png');
  list-style: no-repeat 10pt 10pt;
}

#benefits-icon{
  display: none;
}

.mission{
  padding-bottom: 2%;
}

#benefits-list li{
  display: none;
  font-size: 16pt;
  height: 35pt;
}

.benefits-text > p{
    font-size: 14pt;
}

// .bullet-line{
//   position: absolute;
//   left: -2.65vw;
//   width: 4vw;
//   height: auto;
// }

.bullet-line{
  position: absolute;
  right: 48.9vw;
  top: 20%;
  width: 4vw;
  height: auto;
}


.benefits-text{
  padding-left: 5%;
  font-size: 16pt;
}

.benefits-col{
  padding-top: 5%;
}

.landing-img {
    display: block;
    margin: auto;
    width: 30%;
    //height: 10vh;
}

.hero--services > .row{
  display: flex;
}

.landing-links{
  //display: flex;
}

@media (max-width: 1450px){
  .hero__background {
    height: 180vh;
  }
  .hero--asset {
    background-size: 90% !important;
    height: 180vh;
  }
  .hero-parallax {
    background-size: 115% !important;
    height: 180vh;
  }
}

@media (max-width: 1375px){
  .hero__background {
    height: 200vh;
  }
  .hero--asset {
    background-size: 90% !important;
    height: 200vh;
  }
  .hero-parallax {
    background-size: 115% !important;
    height: 200vh;
  }
  .hero--arrow--text {
    margin-left: -30%;
  }
  .services-spec {
    height: 25vh;
  }
}

@media (min-width: 2500px){
  .hero__background {
    height: 180vh;
  }
  .hero--asset {
    background-size: 85% !important;
    height: 180vh;
  }
  .hero-parallax {
    background-size: 100% !important;
    height: 180vh;
  }
  .hero__benefits {
    min-height: 40vh;
  }
}

@media (max-width: 1300px){

  .hero--engineer .hero__services {background-position-x: -10%;}
  .hero--creator .hero__services {background-position-x: 118%;}
  .hero--tracker .hero__services {background-position-x: -13%;}
  .services-btn.p-btn {left: 72%;}
  .services-btn.t-btn {left: 11%;}
  .services-btn.e-btn {left: 14%;}

  .dp__services {
    // padding-bottom: 15%;
  }

  .dp__services > .btn-container {
    // bottom: 15%;
  }

  .services-spec {
    height: 30vh;
  }
  .hero__background {
    height: 240vh;
  }

  #benefits-list li {
    display: none;
    font-size: 12pt;
    height: 45pt;
}

 .hero--asset {
      background-size: 105% !important;
      height: 240vh;
  }
  .hero-parallax{
    height: 240vh;
    position: fixed;
    background-size: 115% !important;
  }
  #arrow {
    left: 15%;
    top: -40%;
  }
  .hero--border{
    top: -2%;
  }
  .benefits-col {
    padding-left: 12%;
    padding-bottom: 5%;
  }
  .bullet-line {
    left: 13.4%;
  }
  .hero__benefits{
    min-height: 75vh;
  }
  .hero--arrow--text {
    margin-left: -30%;
  }
}

@media (max-width: 1200px){
  .bullet-line {
    left: -15.5%;
  }
}
@media (max-width: 1024px){
  .hero__background {
    height: 130vh;
  }
 .hero--asset {
      background-size: 160% !important;
      height: 130vh;
  }
  .hero-parallax{
    height: 130vh;
    position: fixed;
    background-size: 207% !important;
  }
  #arrow {
    left: 15%;
    top: -25%;
  }
  .hero--border{
    top: -1%;
  }
  .benefits-col {
    padding-left: 20%;
    padding-bottom: 5%;
  }
  .bullet-line {
    width: 8vw;
    left: 13.4%;
  }
  .hero__benefits{
    min-height: 75vh;
  }
}

@media (max-width: 800px){
  .services-btn{
    position: relative;
    padding-bottom: 10%;
    left: 0% !important;
  }
  .hero__background {
    height: 170vh;
  }
  .power {
    padding-left: 0%;
}
 .hero--asset {
      background-size: 160% !important;
      height: 170vh;
  }
  .hero-parallax{
    height: 170vh;
    position: fixed;
    background-size: 150% !important;
    background-position: 70% 130% !important;
  }
  .background-text {
    padding-left: 2%;
    padding-top: 5%;
    padding-right: 2%;
}
 .hero--asset {
      background-size: 126% !important;
      height: 170vh;
  }
  .bullet-line {
    left: 11.3%;
  }
}

@media (max-width: 400px){
  .dp__services {
    margin-left: 5%;
    margin-right: 5%;
}
  .services-spec {
    padding-top: 2%;
    padding-bottom: 2%;
    height: 30vh;
  }
  .service-text{
    padding-bottom: 50% !important;
  }
  .hero__services{
    background-attachment: fixed;
    background-position: center bottom;
    background-position-y: 105% !important;
  }
 .hero--asset {
      background-size: 190% !important;
      height: 250vh;
  }
  .hero-parallax{
    height: 250vh;
    position: fixed;
    background-size: 150% !important;
    background-position: 95% 110% !important;
  }
  .hero__background {
    height: 250vh;
  }
  .hero--border{
    top: -1%;
  }
  .benefits-col {
    padding-left: 20%;
    padding-bottom: 5%;
  }
  .bullet-line {
    left: 6.7%;
    top: 20%;
  }
  .hero__benefits{
    min-height: 120vh;
  }
  .benefits-text {
    padding-left: 15%;
  }
  #benefits-list {
    padding-left: 18%;
  }
  .hero__skills .skills-slide__slide {
    margin-left: 0%;
  }
  .background__logo {
    margin-top: 10vh;
    padding-left: 0%;
  }
  .hero--arrow{
    height: 130px;
  }
  #arrow {
    left: 30%;
    top: -12%;
  }
  #benefits-list li {
    display: none;
    font-size: 11pt;
    height: 40pt;
  }
}

@media (min-width: 1200px){
.hero__challenges .challenge .btn-xray{
    position: absolute;
    bottom: 65px;
    right: 32px;
  }
}

.icon-col{
  padding-left: 0px;
  padding-right: 0px;
}

@media (min-width: 1200px)
{
  .hero__challenges .challenge {
    padding: 20px 25px 20px 32px;
  }
}
