.social-media {
  margin-bottom: 0;
  display: inline-block;

  @media (max-width: @screen-xs-max) {
    margin-bottom: @line-height-computed;
    display: block;
  }

  a {
    font-size: @font-size-h1;
    margin: 0 @padding-xs-horizontal;

    &:first-child {
      margin-left: 0;
    }
  }

  &--twitter:hover {
    color: #55acee;
  }
  &--facebook:hover {
    color: #3b5998;
  }
  &--linkedin:hover {
    color: #0977b5;
  }
  &--dribbble:hover {
    color: #ea4c89;
  }
  &--behance:hover {
    color: #1769FF;
  }
  &--instagram:hover {
    color: #3f729b;
  }
  &--pinterest:hover {
    color: #C92228;
  }
}
