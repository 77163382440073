.angled-edges(@height:50%, @angle:1.2deg) {
  position: relative;
  z-index: 1;

  &:before,
  &:after {
    background: inherit;
    content: '';
    display: block;
    height: @height;
    left: 0;
    position: absolute;
    right: 0;
    z-index: -1;
    -webkit-backface-visibility: hidden;
  }

  &:before {
    border-top: 1em solid @white;
    top: 0;
    transform: skewY(@angle);
    transform-origin: 100% 0;
  }

  &:after {
    bottom: 0;
    transform: skewY((@angle * -1));
    transform-origin: 100%;
    border-bottom: 1em solid @white;
  }
}

.angled-edges-2(@height:10%, @angle:-1.2deg) {
  position: relative;
  z-index: 1;

  &:before,
  &:after {
    background: inherit;
    content: '';
    display: block;
    height: @height;
    left: 0;
    position: absolute;
    right: 0;
    z-index: -1;
    -webkit-backface-visibility: hidden;
  }

  &:before {
    border-top: 1em solid @white;
    top: -20%;
    transform: skewY(@angle);
    transform-origin: 100% 0;
    height: 200%;
  }

  &:after {
    width: 200%;
    height: 0%;
    top: 90%;
    left: -50%;
    z-index: -1;
    transform: skewY((@angle * -1));
    border-bottom: 1em solid @white;
  }
}

.angled-edges-rev(@height:50%, @angle:-1.2deg) {
  position: relative;
  z-index: 1;

  &:before,
  &:after {
    background: inherit;
    content: '';
    display: block;
    height: @height;
    left: 0;
    position: absolute;
    right: 0;
    z-index: -1;
    -webkit-backface-visibility: hidden;
  }

  &:before {
    border-top: 1em solid @white;
    top: 0;
    transform: skewY(@angle);
    transform-origin: 0% 0;
  }

  &:after {
    bottom: 0;
    transform: skewY((@angle * -1));
    transform-origin: 0%;
    border-bottom: 1em solid @white;
  }
}

.angled-bottom(@height:50%, @angle:1.5deg) {
  position: relative;
  z-index: 1;

  &:after {
    background: inherit;
    content: '';
    display: block;
    height: @height;
    left: 0;
    position: absolute;
    right: 0;
    z-index: -1;
    -webkit-backface-visibility: hidden; // for Chrome Windows
  }

  &:after {
    bottom: 0;
    transform: skewY((@angle * -1));
    transform-origin: 100%;
  }
}

.vertical-align() {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.aspect-ratio(@width, @height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ((@height / @width) * 100%);
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
