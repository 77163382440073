

// OPEN SANS
// --------------------------------------------------
// LIGHT
@font-face {
    font-family: 'open_sanslight';
    src: url('../fonts/OpenSans-Light-webfont.eot');
    src: url('../fonts/OpenSans-Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSans-Light-webfont.woff') format('woff'),
         url('../fonts/OpenSans-Light-webfont.ttf') format('truetype'),
         url('../fonts/OpenSans-Light-webfont.svg#open_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;

}

// LIGHT ITALIC
@font-face {
    font-family: 'open_sanslight_italic';
    src: url('../fonts/OpenSans-LightItalic-webfont.eot');
    src: url('../fonts/OpenSans-LightItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSans-LightItalic-webfont.woff') format('woff'),
         url('../fonts/OpenSans-LightItalic-webfont.ttf') format('truetype'),
         url('../fonts/OpenSans-LightItalic-webfont.svg#open_sanslight_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}

// REGULAR
@font-face {
    font-family: 'open_sansregular';
    src: url('../fonts/OpenSans-Regular-webfont.eot');
    src: url('../fonts/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSans-Regular-webfont.woff') format('woff'),
         url('../fonts/OpenSans-Regular-webfont.ttf') format('truetype'),
         url('../fonts/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}


// SEMI BOLD
@font-face {
    font-family: 'open_sansbold';
    src: url('../fonts/OpenSans-Bold-webfont.eot');
    src: url('../fonts/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSans-Bold-webfont.woff') format('woff'),
         url('../fonts/OpenSans-Bold-webfont.ttf') format('truetype'),
         url('../fonts/OpenSans-Bold-webfont.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;

}

// SEMI BOLD ITALIC
@font-face {
    font-family: 'open_sansbold_italic';
    src: url('../fonts/OpenSans-BoldItalic-webfont.eot');
    src: url('../fonts/OpenSans-BoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSans-BoldItalic-webfont.woff') format('woff'),
         url('../fonts/OpenSans-BoldItalic-webfont.ttf') format('truetype'),
         url('../fonts/OpenSans-BoldItalic-webfont.svg#open_sansbold_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}

// OPEN SANS CONDENSED
// --------------------------------------------------
// CONDENSED LIGHT
@font-face {
    font-family: 'open_sanscondensed_light';
    src: url('../fonts/OpenSans-CondLight-webfont.eot');
    src: url('../fonts/OpenSans-CondLight-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSans-CondLight-webfont.woff') format('woff'),
         url('../fonts/OpenSans-CondLight-webfont.ttf') format('truetype'),
         url('../fonts/OpenSans-CondLight-webfont.svg#open_sanscondensed_light') format('svg');
    font-weight: normal;
    font-style: normal;

}

// CONDENSED LIGHT ITALIC
@font-face {
    font-family: 'open_sansCnLtIt';
    src: url('../fonts/OpenSans-CondLightItalic-webfont.eot');
    src: url('../fonts/OpenSans-CondLightItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSans-CondLightItalic-webfont.woff') format('woff'),
         url('../fonts/OpenSans-CondLightItalic-webfont.ttf') format('truetype'),
         url('../fonts/OpenSans-CondLightItalic-webfont.svg#open_sansCnLtIt') format('svg');
    font-weight: normal;
    font-style: normal;

}


// BEBAS NEUE
// --------------------------------------------------
// REGULAR
@font-face {
    font-family: 'bebas_neue_regularregular';
    src: url('../fonts/bebasneue_regular-webfont.woff2') format('woff2'),
         url('../fonts/bebasneue_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

// BOLD
@font-face {
    font-family: 'bebas_neuebold';
    src: url('../fonts/bebasneue_bold-webfont.woff2') format('woff2'),
         url('../fonts/bebasneue_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
