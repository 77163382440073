.spacer {
  display: inline;
  font-size: @font-size-h4;
  line-height: @font-size-h1;
  margin: 0;
  vertical-align: middle;
}

.footer {
  color: @gray;
  padding-bottom: @padding-large-vertical;
  padding-top: @padding-large-vertical;

  &__contact {
    display: inline-block;
    font-family: @font-family-headings;
    font-size: @font-size-h1;
    margin-bottom: 0;

    @media (max-width: @screen-xs-max) {
      display: block;
      line-height: 1;
      margin-bottom: @line-height-computed;
    }

    a {
      &:hover {
        color: @brand-secondary;
        text-decoration: none;

        body.hero--tracker & {
          color: @brand-tracker;
        }

        body.hero--engineer & {
          color: @brand-engineer;
        }

        body.hero--marksman & {
          color: @brand-marksman;
        }

        body.hero--creator & {
          color: @brand-creator;
        }

        body.hero--protege & {
          color: @brand-protege;
        }
      }
    }
  }

  .spacer {
    margin: 0 @padding-large-horizontal;
  }

  &__nav {
    margin-top: @padding-large-vertical;
  }

  .legal {
    font-size: @font-size-small;
    line-height: 1.5;
    margin: 0;
  }
}
