/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
//
// Load core variables and mixins
// --------------------------------------------------
@import "fonts.less";
@import "icons/font-awesome.less";
@import "variables.less";
@import "mixins.less";
@import "theme_mixins.less"; // @import "content.less";
// @import "accordion.less";
// @import "the_heros.less";
// @import "service_marksman.less";
@import "services.less";
@import "heroes.less";
@import "footer.less";
@import "social.less";

body {
  background: @body-bg url( '../img/seamless_paper_texture.png');
}
//
// Typography
// --------------------------------------------------

h2 {
  text-transform: uppercase;
}

.container-fluid {
    //padding-right: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  span {
    color: @text-color;
  }
}
//
// Links
// --------------------------------------------------

a {
  transition: all 0.5s ease;
}
//
// Buttons
// --------------------------------------------------
// Common styles

.btn-danger,
.btn-default,
.btn-info,
.btn-primary,
.btn-success,
.btn-warning {
  text-shadow: none;
  // @shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075);
  // .box-shadow(@shadow);
  // Reset the shadow
  &.active,
  &:active {
    //.box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    .box-shadow(none);
  }

  .badge {
    text-shadow: none;
  }
}
// Mixin for generating new styles

.btn-styles(@btn-color:#555) {
  //#gradient > .vertical(@start-color: @btn-color; @end-color: darken(@btn-color, 12%));
  //.reset-filter(); // Disable gradients for IE9 because filter bleeds through rounded corners; see https://github.com/twbs/bootstrap/issues/10620
  background-color: fade(@btn-color, 25%);
  background-repeat: repeat-x;
  border-color: @btn-color;
  border-width: 2px;
  font-family: @font-family-headings;
  font-size: @font-size-h3;
  letter-spacing: 0.05em;
  line-height: 1;

  &:focus,
  &:hover {
    background-color: fade(@btn-color, 85%);
    background-position: 0 -15px;
  }

  &.active,
  &:active {
    background-color: darken(@btn-color, 12%);
    border-color: darken(@btn-color, 14%);
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &.active,
    &.focus,
    &:active,
    &:focus,
    &:hover {
      background-color: darken(@btn-color, 12%);
      background-image: none;
    }
  }
}
// Common styles

.btn {
  text-transform: uppercase;
  // Remove the gradient for the pressed/active state
  &.active,
  &:active {
    background-image: none;
  }
}
// Apply the mixin to the buttons

.btn-default {
  .btn-styles(@btn-default-bg);
  border-color: @btn-primary-color;
}

.btn-primary {
  .btn-styles(@btn-primary-bg);
}

.btn-success {
  .btn-styles(@btn-success-bg);
}

.btn-info {
  .btn-styles(@btn-info-bg);
}

.btn-warning {
  .btn-styles(@btn-warning-bg);
}

.btn-danger {
  .btn-styles(@btn-danger-bg);
}

.landing-link-btn{
  margin-top: 15%;
  padding: 2%;
}
//
// Images
// --------------------------------------------------

.img-thumbnail,
.thumbnail {
  .box-shadow(0 1px 2px rgba(0,0,0,.075));
}
//
// Dropdowns
// --------------------------------------------------

.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
  #gradient > .vertical(@start-color: @dropdown-link-hover-bg; @end-color: darken(@dropdown-link-hover-bg, 5%));
  background-color: darken(@dropdown-link-hover-bg, 5%);
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
  #gradient > .vertical(@start-color: @dropdown-link-active-bg; @end-color: darken(@dropdown-link-active-bg, 5%));
  background-color: darken(@dropdown-link-active-bg, 5%);
}
//
// Navbar
// --------------------------------------------------
// Default navbar

.navbar-default {
  z-index: 100;
  // #gradient > .vertical(@start-color: lighten(@navbar-default-bg, 10%); @end-color: @navbar-default-bg);
  // .reset-filter(); // Remove gradient in IE<10 to fix bug where dropdowns don't get triggered
  border-radius: 0;
  // @shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 5px rgba(0,0,0,.075);
  // .box-shadow(@shadow);
  margin-bottom: 0;

  .navbar-nav > .active > a,
  .navbar-nav > .open > a {
    #gradient > .vertical(@start-color: darken(@navbar-default-link-active-bg, 5%); @end-color: darken(@navbar-default-link-active-bg, 2%));
    .box-shadow(inset 0 3px 9px rgba(0,0,0,.075));
  }
}

.navbar-brand,
.navbar-nav > li > a {
  // text-shadow: 0 1px 0 rgba(255,255,255,.25);
  // font-family: @font-family-headings;
  // font-size: @font-size-h3;
  // letter-spacing: .025em;
  // text-transform: uppercase;
}

.navbar-brand {
  padding: 8px 0 8px 15px;
  @media (min-width: @screen-sm-min) {
    padding: 8px 0;
  }

  svg {
    height: 100%;
    width: auto;

    .cls-1 {
      fill: @off-white;

      &:hover {
        fill: @brand-secondary;
      }
    }
  }
}
// Inverted navbar

.navbar-inverse {
  #gradient > .vertical(@start-color: lighten(@navbar-inverse-bg, 10%); @end-color: @navbar-inverse-bg);
  .reset-filter(); // Remove gradient in IE<10 to fix bug where dropdowns don't get triggered; see https://github.com/twbs/bootstrap/issues/10257
  border-radius: @navbar-border-radius;

  .navbar-nav > .active > a,
  .navbar-nav > .open > a {
    #gradient > .vertical(@start-color: @navbar-inverse-link-active-bg; @end-color: lighten(@navbar-inverse-link-active-bg, 2.5%));
    .box-shadow(inset 0 3px 9px rgba(0,0,0,.25));
  }

  .navbar-brand,
  .navbar-nav > li > a {
    // text-shadow: 0 -1px 0 rgba(0,0,0,.25);
  }
}
// Undo rounded corners in static and fixed navbars

.navbar-fixed-bottom,
.navbar-fixed-top,
.navbar-static-top {
  border-radius: 0;
}
// Fix active state of dropdown items in collapsed mode
@media (max-width: @grid-float-breakpoint-max) {
  .navbar .navbar-nav .open .dropdown-menu > .active > a {
    &,
    &:focus,
    &:hover {
      color: #fff;
      #gradient > .vertical(@start-color: @dropdown-link-active-bg; @end-color: darken(@dropdown-link-active-bg, 5%));
    }
  }
}
//
// Nav
// --------------------------------------------------

.nav-pills {
  text-transform: uppercase;
}
//
// Alerts
// --------------------------------------------------
// Common styles

.alert {
  text-shadow: 0 1px 0 rgba(255,255,255,.2);
  @shadow: inset 0 1px 0 rgba(255,255,255,.25), 0 1px 2px rgba(0,0,0,.05);
  .box-shadow(@shadow);
}
// Mixin for generating new styles

.alert-styles(@color) {
  #gradient > .vertical(@start-color: @color; @end-color: darken(@color, 7.5%));
  border-color: darken(@color, 15%);
}
// Apply the mixin to the alerts

.alert-success {
  .alert-styles(@alert-success-bg);
}

.alert-info {
  .alert-styles(@alert-info-bg);
}

.alert-warning {
  .alert-styles(@alert-warning-bg);
}

.alert-danger {
  .alert-styles(@alert-danger-bg);
}
//
// Progress bars
// --------------------------------------------------
// Give the progress background some depth

.progress {
  #gradient > .vertical(@start-color: darken(@progress-bg, 4%); @end-color: @progress-bg)
}
// Mixin for generating new styles

.progress-bar-styles(@color) {
  #gradient > .vertical(@start-color: @color; @end-color: darken(@color, 10%));
}
// Apply the mixin to the progress bars

.progress-bar {
  .progress-bar-styles(@progress-bar-bg);
}

.progress-bar-success {
  .progress-bar-styles(@progress-bar-success-bg);
}

.progress-bar-info {
  .progress-bar-styles(@progress-bar-info-bg);
}

.progress-bar-warning {
  .progress-bar-styles(@progress-bar-warning-bg);
}

.progress-bar-danger {
  .progress-bar-styles(@progress-bar-danger-bg);
}
// Reset the striped class because our mixins don't do multiple gradients and
// the above custom styles override the new `.progress-bar-striped` in v3.2.0.

.progress-bar-striped {
  #gradient > .striped();
}
//
// List groups
// --------------------------------------------------

.list-group {
  border-radius: @border-radius-base;
  .box-shadow(0 1px 2px rgba(0,0,0,.075));
}

.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
  text-shadow: 0 -1px 0 darken(@list-group-active-bg, 10%);
  #gradient > .vertical(@start-color: @list-group-active-bg; @end-color: darken(@list-group-active-bg, 7.5%));
  border-color: darken(@list-group-active-border, 7.5%);

  .badge {
    text-shadow: none;
  }
}
//
// Panels
// --------------------------------------------------
// Common styles

.panel {
  .box-shadow(0 1px 2px rgba(0,0,0,.05));
}
// Mixin for generating new styles

.panel-heading-styles(@color) {
  #gradient > .vertical(@start-color: @color; @end-color: darken(@color, 5%));
}
// Apply the mixin to the panel headings only

.panel-default > .panel-heading {
  .panel-heading-styles(@panel-default-heading-bg);
}

.panel-primary > .panel-heading {
  .panel-heading-styles(@panel-primary-heading-bg);
}

.panel-success > .panel-heading {
  .panel-heading-styles(@panel-success-heading-bg);
}

.panel-info > .panel-heading {
  .panel-heading-styles(@panel-info-heading-bg);
}

.panel-warning > .panel-heading {
  .panel-heading-styles(@panel-warning-heading-bg);
}

.panel-danger > .panel-heading {
  .panel-heading-styles(@panel-danger-heading-bg);
}
//
// Wells
// --------------------------------------------------

.well {
  #gradient > .vertical(@start-color: darken(@well-bg, 5%); @end-color: @well-bg);
  border-color: darken(@well-bg, 10%);
  @shadow: inset 0 1px 3px rgba(0,0,0,.05), 0 1px 0 rgba(255,255,255,.1);
  .box-shadow(@shadow);
}
//
// forms
// --------------------------------------------------

.contact-form {
  margin-bottom: (@padding-large-vertical * 6);

  input,
  select,
  textarea {
    border: none;
    border-radius: 0;
    box-shadow: 0 10px 15px -10px fade(@brand-primary, 30%);
  }

  .form-control:focus {
    background-color: @white;
    border-color: @white;
    box-shadow: 0 10px 15px -10px fade(@brand-primary, 60%);
    outline: 0 none;
  }
}
//
// Modal
// --------------------------------------------------
@keyframes animatedLight {
  0% {
    background-position: 0 1000%;
  }

  100% {
    background-position: 0 0;
  }
}

.modal {
  &-content {
    // .box-shadow(0 3px 9px fade(@brand-primary, 85%));
    background-color: transparent;
    // background-image: url('../img/lightthebeacon-on.png');
    // background-repeat: no-repeat;
    // background-size: cover;
    //
    // animation: animatedLight 10s linear 1;
    //
    border-radius: 0;
    border-width: 0;
    box-shadow: none;
    color: @off-white;
    overflow: hidden;
  }

  &-header {
    background-color: transparent;
    border-width: 0;
  }

  &-body {
    min-height: 50vh;
  }

  &-footer {
    background-color: transparent;
    border-width: 0;
  }
}
@keyframes animatedBackground {
  0% {}

  100% {
    background-position: 0 100%;
    background-position-x: -7pt;
  }
}
@keyframes animatedTopBackground {
  0% {
    background-position: 0 -100%;
    background-position-x: -7pt;
  }

  100% {
    background-position: 0 0;
    background-position-x: -7pt;
  }
}

.beacon_is_lit {
  background-image: url("../img/modals/beacon-pic.png");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 100%;
  height: 100%;
  z-Index: -1;
  display: none;
  // background-position-y: 100%;
  animation: slideUp 2s;
}
@keyframes slideUp {
  0% {
    background-position-y: 300%;
  }

  100% {
    background-position-y: 100%;
  }
}

.xrayFormsection {
  display: none;
}

.submittedText {
  display: none;
  text-align: center;
  padding-top: 30%;
}

.main-vid {
  margin-top: -7%;
}

.time_to_light {
  background: url("../img/modals/lightthebeacon.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-position-y: -200pt;
  position: absolute;
  width: 100%;
  height: 100%;
  z-Index: -2;
  display: none;
}

.beacon_light {
  z-index: -1;
}

.hero-caption {
  padding-top: 5%;
}

.hero-caption > h3 {
  font-size: 20pt;
}

.intro-title {
  font-size: 50pt;
  padding-bottom: 5%;
}

.intro-subtitle {
  font-size: 35pt;
}

.hero-title {
  font-size: 40pt;
}

.trust-footer {
  width: 15%;
}

.hero-name {
  font-size: 40pt;
  display: inline-block;
}

#xray {
  // background-image: url('../img/modals/xray.png');
  // background-position: top center;
  // background-repeat: no-repeat;
  // background-size: contain;
  // animation: animatedTopBackground 2s linear 1;
}

.xray-img {
  position: absolute !important;
  width: 110%;
  left: -15% !important;
  top: -150%;
}
//
// General
// --------------------------------------------------

.angled {
  .angled-edges();
  background: @brand-primary;
  color: @off-white;
  padding: (@padding-large-vertical * 3) 0;
  z-index: 1;

  a {
    color: @brand-secondary;
  }
}

.angled-rev {
  .angled-edges-rev();
  background: @brand-primary;
  color: @off-white;
  padding: (@padding-large-vertical * 3) 0;
  z-index: 1;

  a {
    color: @brand-secondary;
  }
}


.intro {
  .angled-bottom(50%, 1.2deg);
  background: @brand-secondary;
  margin-bottom: (@padding-large-vertical * 9);
  padding: (@padding-large-vertical * 3) 0;

  h1 {
    font-size: (@font-size-h1 * 2);
    line-height: 1;
    margin: 0;
    text-align: center;
  }
}
//
// Case Studies (Portfolio)
// --------------------------------------------------

.case-study-list {
  margin-bottom: (@padding-large-vertical * 6);
  margin-top: 0;
  padding-left: (@grid-gutter-width / 2);
  padding-right: (@grid-gutter-width / 2);

  a.case-study__item {
    .aspect-ratio(1, 1);
    background: @gray;
    box-shadow: 0 10px 15px -10px fade(@brand-primary, 60%);
    color: @white;
    display: block;
    margin: 0 0 @grid-gutter-width;
    overflow: hidden;
    text-align: center;
    transition: all 0.5s ease;

    &:hover {
      cursor: pointer;
      top: -1em;
    }

    .content {
      h3 {
        .vertical-align();
        margin: 0;
      }
    }
  }
}

.case-study {
  margin-bottom: (@padding-large-vertical * 6);

  dl {
    margin-bottom: @line-height-computed;

    dt {
      font-family: @font-family-bold;
      font-weight: bold;
    }

    dd {
      margin-bottom: @line-height-computed;
    }
  }

  blockquote {
    border-width: 0;
  }

  &__image {
    box-shadow: 0 10px 15px -10px fade(@brand-primary, 60%);
    margin-bottom: @line-height-computed;
  }
}
//
// Contact page
// --------------------------------------------------

.map--responsive {
  clip-path: polygon(0 2%, 100% 0%, 100% 100%, 0% 100%);
  border-top: 1em solid @text-color;
  margin: 0 -15px;
  overflow: hidden;
  padding-top: 33.33333%;
  position: relative;
  height: 0;

  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}

.alert-cookies {
  background: #000;
  color: #fff;
  border-color: #000;
}

.cookie-link {
  color: #fff;
}

.dp-video {
  max-height: 90%;
  height: 100%;
  width: 100%;
}

.dp-hero {
  max-width: 442px;
}

.dp-hero {
  padding-bottom: 10px;
  padding-top: 10px;
}

.nav-link {
  color: #fff;
}

.nav-link:hover {
  text-decoration: none;
  color: #90AA3C;
}

#topArrow {
  color: #90AA3C !important;
}

.fa-angle-down {
  position: absolute;
  bottom: 10%;
  left: 50%;
  -webkit-animation: jump 1.5s infinite;
  animation: jump 1.5s infinite;
}
@-webkit-keyframes jump {
  0% {
    bottom: 10%;
  }

  50% {
    bottom: 12%;
  }

  100% {
    bottom: 10%;
  }
}
@keyframes jump {
  0% {
    bottom: 10%;
  }

  50% {
    bottom: 12%;
  }

  100% {
    bottom: 10%;
  }
}

.alert-cookies {
  z-index: 10000;
}

.logo_link:active,
.logo_link:hover {
  text-decoration: none;
}

.thumbnail {
  position: relative;
}

.caption {
  width: 100%;
  bottom: 0.3rem;
  position: absolute;
  background: rgba(0, 0, 0, 0);
}

.fix {
  width: 100%;
  padding: 0;
}

.scrolloff {
  pointer-events: none;
}

.thumbnail {
  border: 0 none;
  box-shadow: none;
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0);
}

.offer-intro {
  margin-bottom: 5%;
}

.refering-section {
  margin-top: 5%;
}

.caption h2 {
  font-size: 20pt;
  color: #fff;
  -webkit-font-smoothing: antialiased;
}

body {
  padding-top: 50px;
}

.btn-heros {
  font-size: 15px;
}

.banter-link:hover {
  text-decoration: none;
}

.form-stretch {
  width: 200pt !important;
}

.radio-inline {
  padding-top: 0 !important;
  padding-left: 25pt !important;
}

.icon-img {
  max-height: 200px;
  max-width: 90%;
}

.center-img {
  display: block;
  margin: auto;
}

.confirm {
  margin-top: 15px;
}

.intro-sub {
  color: #fff;
  font-size: 25px;
}

.job-link {
  color: #90AA3C;
}

.job-link:hover {
  color: #90AA3C;
}

.news-container {
  background-color: #fff;
  background-image: url("../img/theDailyPaint-texture.png");
}

#twitter-widget-0,
.fb-page > span > iframe {
  border: 2pt solid rgba(0, 0, 0, 0.9) !important;
}

.blog-img {
  height: 120pt;
  margin: 0 auto;
}

.blog-item {
  margin-bottom: 50pt;
  //background-color: rgba(255, 255, 255, 0.7);
  padding: 2%;
}

.border-left {
  border-left: 2pt solid rgba(0, 0, 0, 0.9);
}

.border-right {
  border-right: 2pt solid rgba(0, 0, 0, 0.9);
}

.news-line {
  border-top: 2pt solid rgba(0, 0, 0, 0.9);
  margin-top: 25px;
}

.news-title-line {
  border-top: 6pt double rgba(0, 0, 0, 0.7);
}

.news-title {
  font-size: 50pt;
  font-weight: bold;
}

.article-big-img {
  max-height: 160pt;
  float: right;
}

.overflow {
  display: none;
}

.article-content {
  padding: 5%;
}

.article-link,
.article-link:hover {
  text-decoration: none !important;
}

.article-column > p {
  font-size: 14pt;
}

a {
  text-decoration: none !important;
}

a:hover {
  color: #90AA3C;
}

.featured-caption {
  color: #fff;
}

.acticle-cover {
  width: 80%;
  height: auto;
  max-width: 840px;
  max-height: 560px;
  margin: 0 auto;
  display: block;
}

.article-logo {
  height: auto;
  width: 70%;
  margin: 0 auto;
  display: block;
  margin-top: 10%;
}

.news-subtitle {
  padding-left: 10pt;
}

.article-title {
  margin-top: 0;
}

#share-buttons > .share {
  padding: 1%;
}

#instafeed {
  padding: 3%;
}

#instafeed > li {
  border: 2pt solid rgba(0, 0, 0, 0.9) !important;
}

.landingTitleBeacon {
  font-size: 40pt;
}

.landingTitle {
  font-size: 60pt;
}
// .landing-links{padding-top: 10%;}
// .landing-btn{margin-left: 20%;}

.landing-links img{

}
.landing-form {
  padding-top: 5%;
}

.landing-footer {
  background: rgba(0, 0, 0, 0.0);
}

.row.landing {
  padding-top: 10vh;
  // min-height: 70vh;
  padding-bottom: 1vh;
}

.landing-link {
  background: #90AA3C;
  // padding-bottom: 10%;
}

.landing-link:hover {
  background: #333;
  z-index: 10;
}

.landing-link > a {
  margin-left: 2%;
  margin-right: 2%;
}

.landingsubmit {
  padding-top: 0;
  margin-top: 27vh;
  background: rgba(255, 255, 255, 0.4);
  padding-bottom: 2%;
  margin-left: 5%;
  margin-right: 5%;
  padding-top: 1%;
}
//test

.landingSubmitBody {
  background: #242424;
  animation: fadein 2s;
  -moz-animation: fadein 3s;
  -webkit-animation: fadein 3s;
  -o-animation: fadein 3s;
}
@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
//test

select {
  text-overflow: ellipsis !important;
}

img.author {
  display: block;
  margin: 0 auto;
  text-align: center;
  height: 60%;
  width: 60%;
}

.blog-title {
  height: 5vh;
}

.caption {
  background-color: rgba(0, 0, 0, 0.5);
}

.caption > h3 {
  font-size: 29pt;
}

.product:hover {
  color: #000 !important;
}

.banter-link:hover {
  color: #000 !important;
}

.landing-img-l {
  width: 175%;
  max-width: 175%;
}

ul.galeria,
ul.galeria > li {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.galeria > li {
  float: left;
}
@media(max-width:639px) {
  ul.galeria > li {
    width: 50%;
  }
}
@media(min-width:640px) {
  ul.galeria > li {
    width: 50%;
  }
}
@media(min-width:768px) {
  ul.galeria > li {
    width: 25%;
  }
}
@media(min-width:992px) {
  ul.galeria > li {
    width: 25%;
  }
}
@media(min-width:1200px) {
  ul.galeria > li {
    width: 25%;
  }
}

ul.galeria > li img {
  width: 100%;
}

.article-feature {
  display: none;
}

.article-edit,
.offer-edit {
  display: none;
}

.light-text {
  color: #fff !important;
}
//easter egg hunt

.egg > img {
  position: absolute;
  height: 5vh !important;
  z-index: 99;
  bottom: -5%;
  right: 5%;
}

.egg,
.egg > img {
  display: none;
}

.egg2 > img {
  right: 30%;
}

.egg3 > img {
  right: 15%;
}

.egg4 > img {
  bottom: 0;
  right: 0;
}

.egg5 > img {
  bottom: 0;
  right: 0;
}

.egg6 > img {
  bottom: 40%;
  right: 10%;
}

.egg7 > img {
  bottom: 8%;
  left: 7%;
}

.egg8 > img {
  bottom: 40%;
  left: 10%;
}

.egg9 > img {
  bottom: 25%;
  left: 55%;
}

.egg10 > img {
  bottom: 10%;
  right: 5%;
}

.egg11 > img {
  bottom: 80%;
  left: 42%;
}

.egg12 > img {
  bottom: 7%;
  right: 0;
}

.egg13 > img {
  bottom: 10%;
  left: 10%;
}

.egg14 > img {
  bottom: 0;
  right: 0;
}

.egg15 > img {
  bottom: 25%;
  right: -25%;
}
//blog popup

dialog {
  width: 50vw;
  //height: 50vh;
  background-image: url("../img/modals/popup.jpg");
  background-size: contain;
  //background: #333;
  border: 1px solid #dadada;
  font-family: sans-serif;
  padding: 5px 10px 20px 20px;
  z-index: 1020;
  position: fixed;
  top: 30%;
  display: none;
}

.img-article {
  max-width: 18vw;
  max-height: 20vh;
  float: right;
}

ul.centered-pills {
  display: inline-block;
}

.nav-pills > li {
  float: left;
  width: 10vw;
}

.close-light {
  color: #fff;
  opacity: 0.8;
}

.popup--arrow {
  -ms-transform: rotate(-120deg) scaleX(-1);
  -webkit-transform: rotate(-120deg) scaleX(-1);
  transform: rotate(-120deg) scaleX(-1);
  height: 15vh;
  width: auto;
  position: relative;
  top: -50pt;
  right: -25%;
}

.btn-pdf {
  margin-top: 5vh;
}

.popup-text {
  text-align: center;
  color: #fff;
}

.popup-text.line-1 {
  font-size: 56pt;
}

.popup-text.line-2 {
  font-size: 32pt;
}

.popup-text.line-3 {
  font-size: 27pt;
  margin-top: 0;
}

.popup-text.line-4 {
  font-size: 20pt;
  margin-top: 0;
}

.popup-form {
  padding-top: 8vh;
}

.no-gutter [class^="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.hero_tb_img {
  margin: 0 auto;
  max-height: 15vh;
  max-width: 15vw;
}

.headline-img{
  max-height: 25pt;
  max-width: 25pt;
}

//offers

/*============================================================
BACKGROUND COLORS
============================================================*/

.db-bk-color-one {
  background-color: #ff571a;
}

.db-bk-color-two {
  background-color: #ffc30f;
}

.db-bk-color-three {
  background-color: #ce0438;
}

.db-bk-color-one .type{
  color: #ff571a;
}

.db-bk-color-two .type{
  color: #ffc30f;
}

.db-bk-color-three .type{
  color: #ce0438;
}

/*============================================================
PRICING STYLES
==========================================================*/

.db-pricing-eleven {
  // margin-bottom: 30px;
  margin-top: 50px;
  text-align: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, .5);
  color: #fff;
  line-height: 30px;
}

.db-pricing-eleven ul {
  list-style: none;
  margin: 0;
  text-align: center;
  padding-left: 0;
}

.db-pricing-eleven ul li {
  padding-top: 10pt;
  padding-bottom: 10pt;
  cursor: pointer;
}

.db-pricing-eleven ul li i {
  margin-right: 5px;
}

.db-pricing-eleven .price {
  background-color: rgba(0, 0, 0, 0.4);
  padding: 20pt 20px 20px;
  font-size: 60px;
  font-weight: 900;
  color: #FFFFFF;
}

.db-pricing-eleven .price small {
  color: #B8B8B8;
  display: block;
  font-size: 12px;
  margin-top: 22px;
}

.db-pricing-eleven .type {
  background-color: #333;
  padding: 50px 20px;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 30px;
}

.db-pricing-eleven .pricing-footer {
  padding: 20px;
}

.db-attached > .col-lg-3,
.db-attached > .col-lg-4,
.db-attached > .col-md-3,
.db-attached > .col-md-4,
.db-attached > .col-sm-3,
.db-attached > .col-sm-4 {
  padding-left: 0;
  padding-right: 0;
}

.db-pricing-eleven.popular {
  margin-top: 10px;
}

.db-pricing-eleven.popular .price {
    padding-top: 20pt;
}

.row-offers{
  margin-bottom: 10vh;
  margin-top: 10vh;
}

.panel-default > .panel-heading-f{
  color: #fff !important;
  background: #3B5998;
  font-size: 20pt;
}

.panel-default > .panel-heading-t{
  color: #fff !important;
  background: #0084b4;
}

.panel-default > .panel-heading-l{
  color: #fff !important;
  background: #0077B5;
}

.pricing-footer a{
  color: #fff;
}

.offer-title{
  font-size: 50pt;
}

#accordion a{color: #fff !important;}

@media (max-width: 1600px) {
  .hero--protege .hero__masthead {
    background-position: center;
    background-position-y: 15%;
    background-size: 55%;
  }
}
@media (max-width: 1400px) {
  .hero--protege .hero__masthead {
    background-position: center;
    background-position-y: 15%;
    background-size: 70%;
  }
}
@media (max-width: 1200px) {
  .hero--protege .hero__masthead {
    background-position: center;
    background-position-y: 6%;
    background-size: 50%;
  }
}
@media (max-width: 1060px) {
  .hero--protege .hero__masthead {
    background-position: center;
    background-position-y: 0;
    background-size: contain;
  }
  //dialog{height: 35vh;}
}
@media (max-width: 800px) {
  .col-centered {
    float: none;
    margin: 0 auto;
  }
  .popup-text.line-1 {font-size: 20pt;}
  .popup-text.line-2 {font-size: 15pt;}
  .popup-text.line-4 {font-size: 8pt;}

  //dialog{height: 210pt;}

  .hero-name {
    font-size: 30pt;
  }

  .hero--protege .hero__masthead {
    background-position: center;
    background-position-y: 15%;
    background-size: contain;
  }

  .mobile-hide {
    display: none !important;
  }
}

@media (max-height: 700px) {
  //dialog{height: 60vh;}
  .popup-text.line-4 {font-size: 12pt;}
  .popup--arrow {top:-30pt;}
  dialog{
    width: 90vw;
  }
  .blog-title {
    height: 12vh;
  }
}
