.serivice-brand {
  display: block;
  margin: 0 auto @line-height-computed;

  h1, h2 {
    margin: 0;
  }
  h2 {
    margin-top: (@line-height-computed * 2);
  }
}

.service-list {
  .clearfix();
  list-style-type: none;
  padding: 0;

  li {
    display: inline;
    padding: 0 @padding-small-horizontal (@line-height-computed / 2) 0;
    // margin-bottom: (@line-height-computed / 2);
    // width: 50%;
  }
}


.serivice__hero {
  background: transparent url('../img/marksman-hero.png') top right no-repeat;
  background-size: contain;
}

//
// Service Page
// --------------------------------------------------
.services {
  .intro {
    margin-bottom: 0;
    z-index: 80;
  }
}

.service {

  &-type {
    .angled-bottom(50%, 1.2deg);
    background-color: @brand-secondary;
    color: @brand-primary;
    padding: (@padding-large-vertical * 6) 0;
    z-index: 0;

    .container div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    a {
      color: @off-white;
    }

    &--product {
      a {
        background: fade(@off-white, 75%);
        border: 2px solid @off-white;
        box-shadow: 0 10px 15px -10px fade(@brand-primary, 60%);
        color: @brand-primary;
        display: block;
        padding: @padding-base-vertical @padding-base-horizontal;
        text-align: center;
        min-height: 190px;
      }
    }

    .container {
      // .vertical-align();
      min-height: 5vh;
      position: relative;
    }

    &--tracker {
      background-color: @brand-tracker;
      z-index: 50;

      svg {
        .font--tracker {
          fill: @off-white;
        }
      }
    }
    &--engineer {
      background-color: @brand-engineer;
      z-index: 40;

      svg {
        .font--engineer {
          fill: @off-white;
        }
      }
    }
    &--marksman {
      background-color: @brand-marksman;
      z-index: 30;

      svg {
        .colour--marksman {
          fill: @off-white;
        }
      }
    }
    &--creator {
      background-color: @brand-creator;
      z-index: 20;
    }
    &--protege {
      background-color: @brand-protege;
      z-index: 10;

      svg {
        .colour--protege {
          fill: @off-white;
        }
      }
    }
  }
}

.dp-logo{
  width: 35px;
}

.service-type{
  transition: all 0.5s ease;
}

.close {
    float: right;
    font-size: 27px;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.2;
    filter: alpha(opacity=20);
}

.service-type--product{
  -webkit-transition: all 2s ease;
  -moz-transition: all 2s ease;
  -o-transition: all 2s ease;
  -ms-transition: all 2s ease;
}

h4{
  -webkit-transition: all 2s ease;
  -moz-transition: all 2s ease;
  -o-transition: all 2s ease;
  -ms-transition: all 2s ease;
}

.service-type{
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
}

.directions{
  margin-left: 3.6%;
}

.service-type--product:hover a {
  text-decoration: none;
}
.service-type--product:active a {
  text-decoration: none;
}

.service-type--product > .product > h4, .product-info > h4 {
  font-size: 22pt;
}

// .product-info{
//   color: rgba(0,0,0,1);
//   opacity: 1;
// }

.product-text{display: none;}

//product images
#tracker-product-1{background: url(../img/products/Tracker-1.jpg);}
#tracker-product-2{background: url(../img/products/Tracker-2.jpg);}
#tracker-product-3{background: url(../img/products/Tracker-3.jpg);}


#engineer-product-1{background: url(../img/products/Engineer-1.jpg);}
#engineer-product-2{background: url(../img/products/Engineer-2.jpg);}
#engineer-product-3{background: url(../img/products/Engineer-3.jpg);}

#marksman-product-1{background: url(../img/products/Marksman-1.jpg);}
#marksman-product-2{background: url(../img/products/Marksman-2.jpg);}
#marksman-product-3{background: url(../img/products/Marksman-3.jpg);}

#creator-product-1{background: url(../img/products/Creator-1.jpg);}
#creator-product-2{background: url(../img/products/Creator-2.jpg);}
#creator-product-3{background: url(../img/products/Creator-3.jpg);}

#protege-product-1{background: url(../img/products/Protege-1.png);}
#protege-product-2{background: url(../img/products/Protege-2.jpg);}
#protege-product-3{background: url(../img/products/Protege-3.jpg);}

.product{
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover !important;
}

.service-type--product:hover > .product{
  transform: scale(1.2);
  // background: fade(@off-white, 75%) !important;
}
.product-info{
  background: fade(@off-white, 75%);
  padding: 10px 10px;
  margin-top: 10px;
}

@media (max-width: 1000px){
  .product{
    width: 70%;
    height: 70%;
  }
  .service-type--product{
    padding: 20px 20px;
    flex-direction: row !important;
  }
}

body{
  overflow-x: hidden;
}

.services_col{
  background: fade(@off-white, 30%);
  border: 2px solid @off-white;
  min-height: 190px;
}
